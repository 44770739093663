import type { ItemsListRequestParams } from '~/shared/types/items-list.type';

export interface BackendItemsListRequestParams {
  page: number;
  per_page: number;
}

export function itemsListRequestParamsMapper(
  paginationData: ItemsListRequestParams,
): BackendItemsListRequestParams {
  return {
    page: paginationData.page,
    per_page: paginationData.perPage,
  };
}
